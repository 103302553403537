$primary-color: #2269dd;
$primary-font: "Poppins", sans-serif;
$input-bg: rgba(0, 145, 234, 0.02);
$switch-active-bg: rgba(0, 145, 234, 0.2);
$input-border: #ced4da;
$input-text: #495057;
$input-label-text: #757575;
$seech-black: #333333;
$text-dark-100: #838383;
$text-dark-200: #959aa3;
$text-dark-300: #666;
$text-dark-400: #979797;
$text-dark-500: #323c47;
$text-dark-600: #030229;
$white: #fff;
$black: #000;
