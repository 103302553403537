@import "./mixins";
@import "./variables";

.mat-mdc-form-field {
    --mat-mdc-form-field-floating-label-scale: 1 !important;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
	position: static !important;
	top: 0;
	left: 0;
	right: 0;
	padding: 0 !important;
}

.mdc-switch {
	&__icon {
		display: none;
	}

	& + label {
		padding-left: 10px !important;
		font-size: 15px;
	}
}

.mat-mdc-form-field-bottom-align::before {
	content: unset !important;
}

.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
	font-size: 14px !important;
	font-weight: 300 !important;
}

.mat-mdc-form-field-error {
	font-size: 12.5px !important;
	font-weight: 400 !important;
}


.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
	background: white !important;
}

.mat-mdc-slide-toggle .mdc-switch {
    width: 2.5rem !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::before {
	background-color: #818181 !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__ripple {
	opacity: 0;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
    background: $switch-active-bg !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after {
	background: $primary-color !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled:focus:not(:active) .mdc-switch__handle::after {
	background: $primary-color !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__track {
    height: 15px !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle {
	@include equalSize(21px);
}
