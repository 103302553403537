@import './mixins';

.auth {
    &-text {
        &-xs {
            @include font(14px, false, 300, 18px);
        }

        &-sm {
            @include font(14px, false, 400, 1.5);
        }

        &-base {
            @include font(16px, false, 400, 1.5);
        }
    }
}