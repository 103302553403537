// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;

// Plus imports for other components in your app.
/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/base";
@import "./assets/scss/mat-overrides";
@import "./assets/scss/ripple-effect";
@import "./assets/scss/common";
@import "./assets/scss/form-field";

$auth-typography: mat.define-typography-config(
  $font-family: $primary-font,
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Auth-primary: mat.define-palette(mat.$light-blue-palette);
$Auth-accent: mat.define-palette(mat.$indigo-palette, A200, A100, A400);

// // The warn palette is optional (defaults to red).
$Auth-warn: mat.define-palette(mat.$red-palette);

// // Create the theme object. A theme consists of configurations for individual
// // theming systems such as "color" or "typography".
$Auth-theme: mat.define-light-theme(
  (
    color: (
      primary: $Auth-primary,
      accent: $Auth-accent,
      warn: $Auth-warn,
    ),
    typography: $auth-typography,
  )
);

// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
@include mat.all-component-themes($Auth-theme);

@layer base {
  html,
  body,
  .mat-typography {
    font-family: $primary-font !important;
  }
}

.material-icons-outlined {
  font-family: "Material Icons Outlined", "Material Icons",
    "Material Symbols Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

