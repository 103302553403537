button {
  .ripple-effect {
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);
    position: absolute;
    transform: scale(0);
    animation: ripple-effect 0.6s linear;
  }

  &[ripple] {
    overflow: hidden !important;
    position: relative !important;
  }
}

@keyframes ripple-effect {
  to {
    transform: scale(2.5);
    opacity: 0;
  }
}
