
@keyframes spinnerAnimation {
	from {
		transform: rotate(0turn);
	}

	to {
		transform: rotate(1turn);
	}
}

.auth {
	&-page {
		&-heading {
			@include font(20px, $text-dark-500, 400, 1.25);
			text-align: center;
			text-transform: capitalize;
		}

		&-meta {
			@extend %flex;
			@extend %flex-center;
			text-align: center;
			@extend .auth-text-xs;
			color: $text-dark-600;

			&-link {
				@extend .auth-text-base;
				font-weight: 500 !important;
				color: $primary-color !important;
			}
		}
	}
}

.resend {
	&-response {
		&-btn {
			@extend %flex;
			@extend %flex-center;
			background: rgba(244, 248, 249, 0.5);
			border: 1px solid $primary-color;
			;
			color: $primary-color;
			padding: 9px 22.5px;
			@include radius(67px);
			outline: none;

			&:disabled {
				opacity: 0.65;
				cursor: not-allowed;
			}

			&-spinner {
				border-top-color: $primary-color !important;
			}

			&-text {
				@include font(14px, inherit, 600, 16px);
			}
		}
	}
}

.back-to-signin {
	@extend .auth-text-base;
	&-link {
		color: $primary-color;
	}
}

.back-url {
	&-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 30px;
		position: relative;

		@include breakpoint(phablet) {
			display: block;
		}
	}

	&-btn {
		position: absolute;
		left: 2rem;
		top: 2rem;
		transform: translateY(-50%);
		display: flex;
		align-items: center;
		color: $primary-color !important;

		@include breakpoint(phablet) {
			position: static;
			margin-bottom: 5px;
		}

		&-text {
			@extend .auth-text-xs;
			margin-left: 5px;
		}

		&-icon {
			width: 21px;
			font-size: 17px;
		}
	}
}

.btn {
	&-auth {
		@extend %flex;
		@extend %flex-center-vert;
		position: relative;
		@include radius(100px);
		max-width: 348px;
		width: 100%;
		margin-bottom: 20px;
		background-color: $primary-color;
		color: white;
		@include flex-center;
		padding: 13px 20px;
		outline: none;
		border: none;
		@extend .auth-text-base;
		text-transform: capitalize;
		word-wrap: break-word;

		&:disabled {
			opacity: 0.65;
			cursor: not-allowed;
		}

		@include breakpoint(phablet) {
			max-width: 100%;
		}

		&--prev {
			background-color: $primary-color;
			color: white;
			padding: 5px 13px;
			@include flex-center;
			@include radius(20px);
			outline: none;
			border: none;

			&-text {
				margin-left: 5px;
			}
		}

		&-icon {
			color: white;
			position: absolute;
			right: 20px;
		}

		&-spinner {
			position: absolute;
			left: 20px;
		}
	}

}

.spinner {
	flex: none;
	@extend %inline-block;
	@include equalSize(1rem);
	border-width: 4px;
	border-style: solid;
	border-color: white transparent transparent;
	border-radius: 50%;
	animation: spinnerAnimation 1s ease infinite;
}
