@import './mixins';
@import './variables';
@import './typography';

input {
    outline : none !important;
}

%valid-label {
    color: $primary-color;
    border-color: $primary-color;
    @extend %radius;
}

%valid-input {
    border-color: $primary-color;
    @extend %radius;
}

%invalid-input,
%invalid-label {
    color: red;
    border-color: red;
    @extend %radius;
}

.form-field {
    @extend %block;
    @extend %relative;
    @extend %w-full;

    &-label {
        @extend %absolute;
        top: 16px;
        left: 15px;
        color: $input-label-text;
        font-weight: 300;
        border-width: 0.5px;
        border-style: solid;
        border-color: rgba($primary-color, 0.5);
        transition: all 0.2s ease-in-out;
        padding: 3px 6px;
        @extend %radius;
        background-color: transparent;
        z-index: 10;
        font-size: 14px;

        @include breakpoint(phablet) {
            left: 10px;
        }
    }

    &-input {
        @include distinctSize(100%, 56px);
        background-color: $input-bg;
        @extend .auth-text-base;
        color: $input-text;
        padding: 15px;
        border-width: 1px;
        border-style: solid;
        border-color: transparent;
        border-bottom-color: $input-border;
        transition: all 0.2s ease-in-out;

        @include breakpoint(phablet) {
            padding: 10px;
        }

        &.ng-valid {
            color: $input-text !important;
        }

        &:focus,
        &:focus-visible,
        &:focus-within,
        &.focused {
            @extend %valid-input;
            & + .form-field-label {
                @extend %valid-label;
            }
            
            &.ng-invalid.ng-touched.ng-invalid.ng-dirty {
                @extend %invalid-input;

                & + .form-field-label {
                    @extend %invalid-label;
                }
            }

            & + .form-field-label {
                top: -12px;
                font-size: 0.8rem;
                background-color: white;
            }
        }

        &.not-empty,
        &.cdk-text-field-autofilled {
            &:focus,
            &:focus-visible,
            &:focus-within,
            &.focused {
                @extend %valid-input;

                & + .form-field-label {
                    @extend %valid-label;
                }

                &.ng-invalid.ng-touched.ng-invalid.ng-dirty {
                    @extend %invalid-input;

                    & + .form-field-label {
                        @extend %invalid-label;
                    }
                }
            }

            & + .form-field-label {
                top: -12px;
                font-size: 0.8rem;
                background-color: white;
            }
        }

        &.ng-invalid.ng-touched.ng-invalid.ng-dirty {
            @extend %invalid-input;

            & + .form-field-label {
                @extend %invalid-label;
                font-size: 0.8rem;
            }
        }

        &:not(.not-empty, .focused) {
            & + .form-field-label { 
                border-color: transparent !important;
                padding: 0 !important;
            }
        }

        &-icon {
            @extend %absolute;
            right: 15px;

            &--eye {
                top: 16px;
            }
    
            &--calendar {
                top: 3px;
                right: 0;
            }

            @include breakpoint(phablet) {
                right: 10px;
            }
        }

        
    }
}

