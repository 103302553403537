@import './mixins';

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    // Overwrite provided to prevent style clash between tailwind and material 
    border-width: unset;
    border-style: unset;
    border-color: unset;
}

html,
body {
    @extend %equalSizeFull;
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
}

app-root {
    @extend %equalSizeFull;
}

body {
    position: relative;
    color: #838383;

    &::before {
        @include pseudo(block);
        @include positionLeftTop(false, 0, 0, false);
        @include equalSize(100%);
        background: center/cover no-repeat;
        background-image: url('../images/mobile/bg-mobile2.png');

        @media only screen and (min-width: 768px) {
            background-image: url('../images/tablet/bg-tablet.png');
            opacity: 0.8;
        }


        @media only screen and (min-width: 992px) {
            background-image: url('../images/desktop/bg-desktop1.png');
            opacity: 0.3
        }

    }
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition: background-color 600000s 0s !important;
}

input[data-autocompleted] {
    background-color: transparent !important;
}