@import "./variables";

// Mixins
// For size dimensions
@mixin equalSize($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin distinctSize($width, $height) {
  width: $width;
  height: $height;
}

%w-full {
  width: 100%;
}

%h-full {
  height: 100%;
}

%equalSizeFull {
  @include equalSize(100%);
}

// For fonts
@mixin font($size: false, $color: false, $weight: false, $lh: false) {
  @if $size {
    font-size: $size;
  }

  @if $color {
    color: $color;
  }

  @if $weight {
    font-weight: $weight;
  }

  @if $lh {
    line-height: $lh;
  }
}

// For responsive media queries
$breakpoints: (
  "phone-smallest": 300px,
  "phone-small": 349.98px,
  "phone": 399.98px,
  "phone-wide": 479.98px,
  "phablet": 575.98px,
  "mobile-device": 599.98px,
  "tablet-small": 639.98px,
  "tablet-variant": 699.98px,
  "tablet": 767.98px,
  "tablet-medium": 990.98px,
  "tablet-wide": 1023.98px,
  "desktop-medium": 1199.98px,
  "desktop": 1279.98px,
  "desktop-wide": 1439.98px,
  "big-screen": 1699.98px
);

@mixin breakpoint($width, $type: max) {
  @if map-has-key($breakpoints, $width) {
    $width: map-get($breakpoints, $width);

    @if $type == min {
      $width: $width + 0.2px;
    }

    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}


// Center elements with horizontal auto margin
@mixin center-margin {
  margin: {
    left: auto !important;
    right: auto !important;
  }
}

@mixin margin-x($left, $right: $left) {
  margin-left: $left;
  margin-right: $right;
}

@mixin margin-y($top, $bottom: $top) {
  margin-top: $top;
  margin-bottom: $bottom;
}

@mixin padding-x($left, $right: $left) {
  padding-left: $left;
  padding-right: $right;
}

@mixin padding-y($top, $bottom: $top) {
  padding-top: $top;
  padding-bottom: $bottom;
}

// Position mixins and extends

// Pseudo before/after element mixin with extend
@mixin pseudo($display: inline-block, $pos: absolute, $content: '') {
  content: $content;
  display: $display;
  position: $pos;
}

@mixin positionLeftTop($pos: false, $left: false, $top: false, $zIndex: false) {
  @if $pos {
    position: $pos;
  }

  @if $left {
    left: $left;
  }

  @if $top {
    top: $top;
  }

  @if $zIndex {
    z-index: $zIndex;
  }
}

@mixin positionrightBottom($pos: false, $bottom: false, $right: false, $zIndex: false) {
  @if $pos {
    position: $pos;
  }

  @if $bottom {
    bottom: $bottom;
  }

  @if $right {
    right: $right;
  }

  @if $zIndex {
    z-index: $zIndex;
  }
}

%pseudo {
  @include pseudo;
}

// Placeholder mixin for forms
@mixin input-placeholder {
  &.placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

// Flex and Display Extends
%block {
  display: block;
}

%inline-block {
  display: inline-block;
}

%inline {
  display: inline;
}

%none {
  display: none;
}

%relative {
  position: relative;
}

%absolute {
  position: absolute;
}



// Kept separate so that it can easily be reused/ extended with other flex mixins and/or extends
@mixin flex {
  display: flex;
}

@mixin flex-column {
  flex-direction: column;
}

@mixin flex-row {
  flex-direction: row;
}

@mixin flex-center-horiz {
  justify-content: center;
}

@mixin flex-center-vert {
  align-items: center;
}

@mixin flex-center-column {
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@mixin flex-size($dimension) {
  max-width: $dimension;
  flex-basis: $dimension;
}

@mixin flex-center {
  align-items: center;
  justify-content: center;
}

%flex {
  @include flex;
}

%flex-column {
  @include flex-column;
}

%flex-row {
  @include flex-row;
}

%flex-center {
  @include flex-center;
}

%flex-center-column {
  @include flex-center-column;
}

%flex-center-horiz {
  @include flex-center-horiz;
}

%flex-center-vert {
  @include flex-center-vert;
}

%flex-center-vert-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

%flex-between {
  justify-content: space-between;
}

%flex-center-horiz-column {
  display: flex;
  align-items: center;
  flex-direction: column;
}

%flex-center-vert-column {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

// Appearance Mixins and Extends
@mixin radius($radius: 4px) {
  border-radius: $radius;
}

%radius {
  @include radius;
}

%radius-full {
  @include radius(100%);
}

@mixin border($border-color) {
  border: 1px solid $border-color;
}

@mixin border-bottom($border-color) {
  border-bottom: 1px solid $border-color;
}

@mixin border-top($border-color) {
  border-top: 1px solid $border-color;
}

@mixin border-right($border-color) {
  border-right: 1px solid $border-color;
}

@mixin border-left($border-color) {
  border-left: 1px solid $border-color;
}


@mixin bg($bg-color) {
  background-color: $bg-color;
}

%bg-primary {
  @include bg($primary-color !important);
}

%bg-white {
  @include bg($white);
}


%seech-user-btn {
  @extend %flex;
  @extend %flex-center;
  @extend %radius;
  letter-spacing: 0.05em;
  @include font(22px, $white, bold, 26px);
  padding: 12px 5px;
  width: 222px;
}

%link-letter-spacing {
  letter-spacing: -0.286842px;
}

%seech-user-default-shadow {
  box-shadow: 0px 25px 50px rgba(238, 242, 244, 0.5);
}



%seech-user-font {
  @include font(22px, $text-dark-100, bold, 26px);
}